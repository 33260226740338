.payment-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f3f5;
  margin-bottom: 2rem;
  border-radius: 20px;
 
}

.payment-card__description {
  padding-left: 1rem;
}

.payment-card__description p {
  color: #3f4254;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.payment-card__description span {
  color: #375bd2;
  font-weight: 400;
}

.payment-card__surveys-available {
  flex: 2;
  text-align: center;
  color: #7b7d8a;
}

.select {
  background-color: #375bd2;
  padding: 1.3rem;
  color: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  transition: all .3s linear;
}

.recommended {
  background: #d9e1f9;
  animation: pulse 2s infinite;
}

.recommended .payment-card__description span {
  font-weight: bold;
}

.recommended .select {
  padding: 1.4rem;
}

.select:hover {
    background: #5274e3;
}


@keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(188, 200, 236, 0.4);
      box-shadow: 0 0 0 0 rgba(188, 200, 236, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(188, 200, 236, 0);
        box-shadow: 0 0 0 10px rgba(188, 200, 236, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(188, 200, 236, 0);
        box-shadow: 0 0 0 0 rgba(188, 200, 236, 0);
    }
  }
