.sd-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: static;
  width: 100%;
  height: calcSize(6);
  box-sizing: border-box;
  padding: calcSize(1.5) calcSize(2);
  line-height: calcSize(3);
  font-family: $font-family;
  font-size: calcSize(2);
  color: $foreground;
  background-color: $background-for-editors;
  box-shadow: inset 0px 1px 2px $shadow-inner;
  border: none;
  border-radius: 3px;
  text-align: start;
}
.sd-input.sd-input:focus {
  outline: none;
}
.sd-input--disabled {
  background-color: $background-for-editors;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sd-input::placeholder {
  color: $foreground-light;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sd-input--disabled,
.sd-input--disabled::placeholder {
  color: $foreground-disabled-for-editors;
}
.sd-root--readonly .sd-input--disabled,
.sd-root--readonly .sd-input--disabled::placeholder {
  color: $foreground;
}
.sd-input:focus {
  box-shadow: 0 0 0 2px $primary, inset 0px 1px 2px $border-inside;
}
.sd-input--error {
  background-color: $red-light;
}

@mixin runnable-track {
  background-color: #19b394;
  border-radius: calcSize(1);
  height: calcSize(1.5);
}
@mixin slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: calcSize(-0.75);
  border-radius: 100%;
  border: 2px solid $primary;
  box-shadow: inset 0 0 0 2px var(--background, #fff);
  background-color: #19b394;
  height: calcSize(3);
  width: calcSize(3);
}

.sd-input[type="range"]::-webkit-slider-runnable-track  {
  @include runnable-track;
}
.sd-input[type="range"]::-webkit-slider-thumb {
  @include slider-thumb;
}
//mozilla
.sd-input[type="range"]::-moz-range-track {
  @include runnable-track;
}
.sd-input[type="range"]::-moz-range-thumb {
  @include slider-thumb;
}