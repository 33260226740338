.title {
    text-align: center;
    margin-bottom: 2rem;
    color: #3F4254;
    letter-spacing: .50px;
    font-size: 1.1rem;
}

.title__survey--used {
    color: #7b7d8a;
   text-align: center;
   margin-bottom: 2rem;
}